import React, { useEffect } from "react";
import { NextUIProvider } from "@nextui-org/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Toaster } from "sonner";
import { LoadingSpinner } from "./components/loading-spinner/LoadingSpinner";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./lib/reactQuery";
import WebApp from "@twa-dev/sdk";

export const AppLoader = () => {
  const App = React.lazy(async () => {
    const res = await Promise.all([
      import("./App"),
      new Promise((r) => setTimeout(r, 950)),
    ]);
    return res[0];
  });
  
  useEffect(() => {
    if (!WebApp.isExpanded) {
      WebApp.expand();
    }
  }, [WebApp.isExpanded]);

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Bird" defaultTitle="Bird" />
        <NextUIProvider>
          <React.Suspense fallback={<LoadingSpinner />}>
            <App />
          </React.Suspense>
          <Toaster richColors position="top-right" />
        </NextUIProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
};
