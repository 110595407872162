import { Buffer } from 'buffer'

if (!Array.prototype.at) {
  Array.prototype.at = function at(index) {
    if (index < 0) {
      return this[this.length + index];
    }
  
    return this[index];
  }
}

globalThis.Buffer = Buffer;