import * as Sentry from "@sentry/react";

export const isSentryEnabled = () => Boolean(import.meta.env.VITE_SENTRY_DSN);

if (isSentryEnabled()) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,

    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      /^\//,
      /^https:\/\/wallet\.birds\.dogs/,
      /^https:\/\/worm\.birds\.dogs/,
    ],

    ignoreErrors: [
      /You must set up your wallet to be able to check in/,
      /Wallet not initialized/,
      /No valid gas coins/,
      /User rejection/,
      /UnhandledRejection/,
    ],
  });
}
