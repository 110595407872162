import "./config/polyfill"
import './instrument'
import React from "react";
import { createRoot } from "react-dom/client";

import "./globals.scss";
import WebApp from "@twa-dev/sdk";
import { AppLoader } from "./AppLoader";

WebApp.ready();
if (WebApp.disableVerticalSwipes) {
  WebApp.disableVerticalSwipes();
}
if ((window as any).BirdWalletDapp) {
  (window as any).BirdWalletDapp.setupMessageProxy(
    WebApp.initDataUnsafe.user?.id?.toString()
  );
}
createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppLoader />
  </React.StrictMode>
);
