
export const LoadingSpinner = () => {
  return (
    <div className="flex w-full flex-col gap-5 h-[100vh] justify-center items-center relative z-[999999]">
      <img src="https://asset.birds.dog/intro/logo.gif" width={115} height={115} alt="" className="mr-2"/>
      <div className="flex justify-center items-center">
        <div className="dot1 bg-gray-800 w-2.5 h-2.5 mx-1 rounded-full animate-loading"></div>
        <div className="dot2 bg-gray-800 w-2.5 h-2.5 mx-1 rounded-full animate-loading delay-200"></div>
        <div className="dot3 bg-gray-800 w-2.5 h-2.5 mx-1 rounded-full animate-loading delay-400"></div>
      </div>
    </div>
  );
};
