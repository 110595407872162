import { QueryClient, MutationCache } from "@tanstack/react-query";
import { isAxiosError } from "axios"

const MAX_RETRIES = 3
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (failureCount >= MAX_RETRIES) {
          return false
        }

        if (
          isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          console.info(`Aborting retry due to ${error.response?.status} status`)
          return false
        }

        return true
      },
    },
  },
  mutationCache: new MutationCache({}),
});

export { queryClient };
